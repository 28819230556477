<script setup lang="ts">
import { state, onFinish, comIdx, fileIdx, openSetting, llmModeOn, toggleLlmMode, title } from './common';
const closeSetting = () => {
  state.bShowSetting = false
}

</script>

<template>
  <!-- <div class="cards" v-show="state.linkState && comIdx == 2"> -->
  <!-- 设置 -->
  <div v-if="comIdx === 0" @click="() => onFinish('')" class="entry" style="z-index: 999999999;">
    欢迎体验源创AGI, 点我开始吧
  </div>
  <div id="remote"></div>

  <div class="setting" v-if="state.bShowSetting">
    <div style="display: flex;justify-content: space-between;margin-bottom: 20px;">
      <span class="setting-title">参数配置</span>
      <img src="/tts/x1.svg" class="setting-close" alt="" @click="closeSetting">
    </div>
    <div class="item">
      <span class="label">选择数字人</span>
      <select class="form-select item-value">
        <option selected>悠哉</option>
      </select>
    </div>
    <div class="item">
      <span class="label">选择音色</span>
      <select class="form-select item-value">
        <option selected>悠哉</option>
      </select>
    </div>
    <div class="item">
      <span class="label">选择ASR</span>
      <select class="form-select item-value">
        <option></option>
      </select>
    </div>
    <div class="item">
      <span class="label">选择TTS</span>
      <select class="form-select item-value">
        <option></option>
      </select>
    </div>
    <div class="item">
      <span class="label">本地文档</span>
      <div class="file-item" v-for=" i in 1" @click="() => fileIdx = i" :class="{ active: fileIdx === i }">
        <span>招聘手册.doc</span>
        <img class="close-icon" :src="`/tts/x${fileIdx === i ? 2 : 3}.svg`" alt="">
      </div>
      <div style="position: relative;">
        <img src="/tts/upload.svg" alt="">
        <input type="file" style="width: 100%;height: 100%;position: absolute;top: 0;left: 0;opacity: 0;" />
      </div>
    </div>
  </div>
  <div class="title">
    <span>{{ title }}</span>
    <div class="form-check form-switch">
      <input class="form-check-input" type="checkbox" :checked="llmModeOn" @change="toggleLlmMode" />
      <label class="form-check-label" for="">LLM</label>
    </div>
    <img class="icon-setting" src="/tts/setting.svg" alt="" @click="openSetting">
  </div>
</template>
<style>
video::-webkit-media-controls-start-playback-button {
  display: none;
}

#app,
body {
  background-color: #F4F5F9;
}
</style>
<style lang="scss" scoped>
#remote {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.title {
  position: fixed;
  width: 100%;
  left: 0;
  padding: 0 20px;
  box-sizing: border-box;
  font-family: AlimamaShuHeiTi, AlimamaShuHeiTi;
  font-weight: bold;
  font-size: 20px;
  color: #252525;
  line-height: 28px;
  text-align: left;
  font-style: normal;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .icon-setting {
    width: 27.58px;
    height: 25px;
    cursor: pointer;
  }
}

@import './index.scss';

.mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .25);
}

.card {
  position: relative;
}

.setting {
  position: absolute;
  position: absolute;
  left: 0;
  z-index: 99999999;
  top: 0;
  right: 0;
  height: 390px;
  box-sizing: border-box;
  padding: 30px 30px 50px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px 3px rgba(214, 214, 214, 0.5);
  border-radius: 0 0 10px 10px;

  .setting-title {
    font-family: AlimamaShuHeiTi, AlimamaShuHeiTi;
    font-weight: bold;
    font-size: 20px;
    color: #252525;
    line-height: 28px;
    text-align: left;
  }

  .setting-close {
    z-index: 99999999;
    width: 20px;
    height: 20px;
  }

  img {
    cursor: pointer;
  }

  .item {
    display: flex;
    line-height: 40px;
    margin-bottom: 14px;

    .label {
      width: 70px;
      margin-right: 17px;
      font-weight: 400;
      font-size: 14px;
      color: #252525;
      text-align: left;
    }

    .item-value {
      width: 240px;
      height: 40px;
      background: #FFFFFF;
      box-shadow: 0px 0px 10px 3px rgba(214, 214, 214, 0.5);
      border-radius: 10px;
    }

    .file-item {
      background: #374151;
      border-radius: 20px;
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
      background: #FFFFFF;
      border-radius: 20px;
      border: 1px solid #374151;
      color: #252525;
      font-size: 12px;
      height: 40px;
      margin-right: 10px;
      align-items: center;
      white-space: nowrap;
      cursor: pointer;

      .close-icon {
        width: 10px;
        height: 10px;
        margin-left: 6px;
      }

      &.active {
        background: #374151;
        color: #FFFFFF;
      }
    }
  }


}


.cards {
  grid-auto-rows: max-content;

  &::after {
    content: "";
    display: block;
    width: 113px;
    /* 设置宽度 */
    height: 27px;
    /* 设置高度 */
    background-image: url('/tts/logo.svg');
    /* 图片路径 */
    background-size: contain;
    /* 使图片适应容器 */
    background-repeat: no-repeat;
    /* 防止图片重复 */
    background-position: center;
    /* 图片居中 */
    position: absolute;
    /* 如果需要控制位置，可以设置 position */
    left: 30px;
    /* 例如，将图片定位到父元素的右侧 */
    top: -47px;
    /* 例如，将图片定位到父元素的顶部 */
  }

}

.cards .card.z-2 {
  display: flex;
  flex-direction: column;
  width: 450px;
  height: 800px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px 3px rgba(214, 214, 214, 0.5);
  border-radius: 20px;
  padding: 20px;
  max-height: 80vh;
  padding: 30px 45px 40px;
}

.btn-change {
  background: #FFFFFF;
  box-shadow: 0px 0px 7px 4px rgba(161, 161, 161, 0.28);
  border-radius: 21px;
  text-align: center;
  line-height: 40px;

  font-family: AlimamaShuHeiTi, AlimamaShuHeiTi;
  font-weight: bold;
  font-size: 14px;
  color: #323864;
  padding: 0 13px;
}
</style>
